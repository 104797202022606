import React from 'react'

import NavBar from '../components/nav'
import Footer from '../components/footer'
import SEO from '../components/seo'
//import catAndHumanIllustration from '../images/cat-and-human-illustration.svg'

export default () => (
    <div>
        <SEO title="" keywords={[`aspen`, `privacy`, `privacy policy`, `grades`, `grade corner`]} />
        <body className="leading-normal tracking-normal text-white gradient">
            <NavBar />
            {/* Hero */}
            <div className="pt-4">
                <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center pb-3">
                    <div className="flex flex-col w-full md:w-4/5 justify-center items-start text-center md:text-left">
                        <h1 className="my-4 text-5xl font-bold leading-tight w-full">Privacy Policy</h1>
                    </div>
                </div>
            </div>

            {/* Policy */}
            <section className="bg-white py-8 border-b ">
                <div className="container mx-auto ">
                    <div className="container w-full mx-auto m-8 text-l text-gray-800 ml-3 ">
                        <p className="mb-3">
                            Edgar Roman built the GradeCorner app as a Freemium app. This SERVICE is provided
                            by Edgar Roman at no cost and is intended for use as is.
                        </p>

                        <p className="mb-3">
                            This page is used to inform visitors regarding my policies with the collection,
                            use, and disclosure of Personal Information of anyone who decide to use my
                            Service.
                        </p>

                        <p className="mb-3">
                            If you choose to use my Service, then you agree to the collection and use of
                            information in relation to this policy. The Personal Information that I collect is
                            used for providing and improving the Service. I will not use or share your
                            information with anyone except as described in this Privacy Policy.
                        </p>

                        <p className="mb-3">
                            The terms used in this Privacy Policy have the same meanings as in our Terms and
                            Conditions, which is accessible at GradeCorner unless otherwise defined in this
                            Privacy Policy.
                        </p>

                        <p className="mb-3">
                            <strong>Information Collection and Use</strong>
                        </p>

                        <p className="mb-3">
                            For a better experience, while using our Service, I may require you to provide us
                            with certain personally identifiable information, including but not limited to
                            Aspen Student Credentials. Student Credentials are only collected when using
                            experimental features, when errors occur, or issues are reported. Aside from those
                            use cases, full Aspen Student Credentials never leave the device.
                        </p>

                        <p className="mb-3">
                            The app does use third party services that may collect information used to
                            identify you. These third party services are used to enhance the Application and
                            never used for marketing or advertising purposes.
                        </p>

                        <p className="mb-3">
                            Link to privacy policy of third party service providers used by the app
                        </p>

                        <ul className="list-disc mb-3">
                            <li className="ml-8">
                                <a href="https://www.google.com/policies/privacy/">Google Play Services</a>
                            </li>
                            <li className="ml-8">
                                <a href="https://sentry.io/privacy/">Sentry</a>
                            </li>
                        </ul>

                        <p className="mb-3">
                            <strong>Log Data</strong>
                        </p>

                        <p className="mb-3">
                            I want to inform you that whenever you use my Service, in a case of an error in
                            the app I collect data and information (through third party products) on your
                            phone called Log Data. This Log Data may include information such as your device
                            Internet Protocol (“IP”) address, device name, operating system version, the
                            configuration of the app when utilizing my Service, the time and date of your use
                            of the Service, and other statistics.
                        </p>

                        <p className="mb-3">
                            <strong>Cookies</strong>
                        </p>

                        <p className="mb-3">
                            Cookies are files with a small amount of data that are commonly used as anonymous
                            unique identifiers. These are sent to your browser from the websites that you
                            visit and are stored on your device’s internal memory.
                        </p>

                        <p className="mb-3">
                            This Service does not use these “cookies” explicitly. However, the app may use
                            third party code and libraries that use “cookies” to collect information and
                            improve their services. You have the option to either accept or refuse these
                            cookies and know when a cookie is being sent to your device. If you choose to
                            refuse our cookies, you may not be able to use some portions of this Service.
                        </p>

                        <p className="mb-3">
                            <strong>Service Providers</strong>
                        </p>

                        <p className="mb-3">
                            I may employ third-party companies and individuals due to the following reasons:
                        </p>

                        <ul className="list-disc mb-3">
                            <li className="ml-8">To facilitate our Service;</li>
                            <li className="ml-8">To provide the Service on our behalf;</li>
                            <li className="ml-8">To perform Service-related services; or</li>
                            <li className="ml-8">To assist us in analyzing how our Service is used.</li>
                        </ul>

                        <p className="mb-3">
                            I want to inform users of this Service that these third parties have access to
                            your Personal Information. The reason is to perform the tasks assigned to them on
                            our behalf. However, they are obligated not to disclose or use the information for
                            any other purpose.
                        </p>

                        <p className="mb-3">
                            <strong>Security</strong>
                        </p>

                        <p className="mb-3">
                            I value your trust in providing us your Personal Information, thus we are striving
                            to use commercially acceptable means of protecting it. But remember that no method
                            of transmission over the internet, or method of electronic storage is 100% secure
                            and reliable, and I cannot guarantee its absolute security.
                        </p>

                        <p className="mb-3">
                            <strong>Links to Other Sites</strong>
                        </p>

                        <p className="mb-3">
                            This Service may contain links to other sites. If you click on a third-party link,
                            you will be directed to that site. Note that these external sites are not operated
                            by me. Therefore, I strongly advise you to review the Privacy Policy of these
                            websites. I have no control over and assume no responsibility for the content,
                            privacy policies, or practices of any third-party sites or services.
                        </p>

                        <p className="mb-3">
                            <strong>Changes to This Privacy Policy</strong>
                        </p>

                        <p className="mb-3">
                            I may update our Privacy Policy from time to time. Thus, you are advised to review
                            this page periodically for any changes. I will notify you of any changes by
                            posting the new Privacy Policy on this page. These changes are effective
                            immediately after they are posted on this page.
                        </p>

                        <p className="mb-3">
                            <strong>Disclosures</strong>
                        </p>

                        <p className="mb-3">
                            GradeCorner and Edgar Roman are not affiliated, associated, authorized, endorsed
                            by, or in any way officially connected with any of the following companies who
                            produce the Student Information Systems we support:
                        </p>

                        <ul className="list-disc mb-3">
                            <li className="ml-8">Follett Corporation</li>
                        </ul>
                    </div>
                </div>
            </section>

            {/* Footer */}
            <Footer></Footer>
        </body>
    </div>
)
